<template>
  <div>
    <!-- customerdetails-left -->
    <div class="customerdetails-left deals-bg">
      <div class="deal-left">
        <div
          class="edit-member blue-circle"
          data-toggle="modal"
          data-target="#editCustomerModal"
        >
          <img
            class="img-fluid"
            src="../../assets/img/pencil.svg"
            alt="Edit Customer"
          />
        </div>
        <!-- profiles -->
        <div class="details-profile">
          <div class="user-img">
            <div class="button-wrapper">
              <a href="#">
                <img
                  v-if="customer_detail.c_img"
                  style="width: 100px; height: 100px"
                  class="img-fluid rounded50"
                  :src="customer_detail.c_img"
                />
                <!--<img v-if="customer_detail.c_img" class="img-fluid rounded50" :src="require('../../assets/images/customers/' + customer_detail.c_img)" /> -->
                <img
                  v-else
                  class="img-fluid"
                  src="../../assets/img/profile.svg"
                  alt=""
                />
              </a>
            </div>
          </div>

          <h3>
            <img
              v-if="customer_detail.c_do_contract == true"
              style="width: 62px; position: relative; top: 18px"
              src="../../assets/img/no_contact.png"
            />{{ customer_detail.c_first_name }}
            {{ customer_detail.c_last_name }}
          </h3>
          <table class="table">
            <tbody>
              <tr>
                <td>Email:</td>
                <td>{{ customer_detail.c_email }}</td>
              </tr>
              <tr>
                <td>Phone:</td>
                <!--<td>{{customer_detail.c_phone | removePlusSign()}}</td>-->
                <td>{{ customer_detail.c_phone }}</td>
              </tr>
              <tr v-if="customer_detail.c_gender">
                <td>Gender:</td>
                <td>{{ customer_detail.c_gender }}</td>
              </tr>
              <tr v-if="customer_detail.c_street">
                <td>Address:</td>
                <td>
                  {{ customer_detail.c_street }}, {{ customer_detail.c_city }},
                  {{ customer_detail.c_state }}
                </td>
              </tr>
              <tr v-if="customer_detail.ls_name">
                <td>Source:</td>
                <td>{{ customer_detail.ls_name }}</td>
              </tr>
              <tr v-if="customer_detail.c_comment">
                <td colspan="2">{{ customer_detail.c_comment }}</td>
              </tr>

              <!-- <tr>
                    <td>Source:</td>
                    <td>
                      <select class="form-control" id="exampleFormControlSelect1">
                        <option>cars.com</option>
                        <option>lotus.com</option>
                        <option>martin.com</option>
                      </select>
                    </td>
                  </tr> -->
            </tbody>
          </table>
          <img
            v-if="customer_detail.c_driving_licence"
            :src="customer_detail.c_driving_licence"
            class="lisense"
          />
          <div class="user-contacts">
            <!-- <ul style="margin-bottom: 50px">
              <li>
                <a href="#"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/Phone (1).svg"
                    alt=""
                  />
                  <p>Call</p></a
                >
              </li>
              <li>
                <a href="#"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/Phone (2).svg"
                    alt=""
                  />
                  <p>Text</p></a
                >
              </li>
              <li>
                <a href="#"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/Group 198.svg"
                    alt=""
                  />
                  <p>Email</p></a
                >
              </li>
            </ul> -->

            <center>
              <a
                @click="addleadsheet"
                style="color: white; cursor: pointer"
                class="rem-btn"
                ><span>Add LeadSheet</span></a
              ><br />
              <a
                @click="mergeCustomerModal"
                style="color: white; cursor: pointer"
                class="rem-btn"
                ><span>Merge Customer</span></a
              ><br />
              <a
                @click="deleteCustomer"
                style="
                  color: white;
                  cursor: pointer;
                  background: #ff4343;
                  border: red;
                "
                class="rem-btn"
                ><span>Delete Customer</span></a
              >

              <!-- <div class="notification" style="position:relative;bottom:40px;right:38px">
                <ul>
                  <li>
                    <a @click="addleadsheet" class="lead-btn"
                      >Add Leadsheet<img
                        class="img-fluid"
                        src="../../assets/img/plus.png"
                        alt=""
                    /></a>
                  </li>
                </ul>
              </div> -->
            </center>
          </div>
        </div>
        <!-- profiles -->
      </div>

      <!-- Leadsheets-table -->
      <div class="Leadsheets-table p-3">
        <div class="deal-title">
          <h3>Leadsheets</h3>
        </div>
        <simplebar class="active-meeting" id="myElement2">
          <div v-if="active_leads.length == 0" class="not-found">
            No Lead Found.
          </div>
          <table class="table" v-if="active_leads.length > 0">
            <thead>
              <tr>
                <th>ID</th>
                <th>Date modified</th>
                <th>Status</th>
                <th>Agent</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="lead in active_leads" :key="lead.l_id">
                <td>
                  <a :href="'/lead-detail/' + lead.l_id">#{{ lead.l_id }}</a>
                </td>
                <td>{{ lead.modified_date }}</td>
                <td>{{ lead.status }}</td>
                <td>{{ lead.agent }}</td>
              </tr>
            </tbody>
          </table>
        </simplebar>
      </div>

      <!-- Leadsheets-table -->
    </div>
    <!-- customerdetails-left -->

    <!-- customerdetails-mid -->
    <div class="customerdetails-mid">
      <!-- Intersets-deal -->
      <div class="Intersets-deal">
        <div class="deal-title">
          <h3>Previous Interests (Lost)</h3>
        </div>
        <simplebar class="customer-activity" id="myElement">
          <div
            class="list-intersets"
            v-for="lead in lost_leads"
            :key="lead.l_id"
          >
            <div class="list-cars">
              <a :href="'/lead-detail/' + lead.l_id">
                <img
                  v-if="lead.v_image"
                  class="img-fluid"
                  :src="lead.v_image"
                  alt=""
                />
                <img
                  v-if="!lead.v_image"
                  class="img-fluid"
                  src="../../assets/img/carplaceholder.png"
                  alt=""
                />
              </a>
            </div>
            <div class="intersets-con">
              <h5>{{ lead.l_created | formatDate("MMMM DD, YYYY") }}</h5>
              <h4>{{ lead.v_year }} {{ lead.v_make }} {{ lead.v_model }}</h4>
              <p>
                <a style="color: black" :href="'/lead-detail/' + lead.l_id">
                  {{ lead.l_id }}</a
                >
              </p>
            </div>
          </div>
          <!-- <div class="list-intersets" v-for="lead in active_leads" :key="lead.l_id">
                <div class="list-cars">
                  <a href="#" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#exampleModal15">
                    <img v-if="lead.v_image" class="img-fluid" :src="lead.v_image" alt="" v-on:click="vehicleDetail(lead)">
                    <img v-if="!lead.v_image" class="img-fluid" src="../../assets/img/carplaceholder.png" alt="">
                  </a>
                </div>
                <div class="intersets-con">
                  <h5>{{lead.l_created | formatDate('MMMM DD, YYYY')}}</h5>
                  <h4>{{lead.v_year}} {{lead.v_make}} {{lead.v_model}}</h4>
                  <p>ID: {{lead.l_id}}</p>
                </div>
              </div> -->
        </simplebar>
      </div>
      <!-- Intersets-deal -->

      <!-- Intersets-deal -->
      <div class="Intersets-deal">
        <div class="deal-title">
          <h3>Previous Deals</h3>
        </div>
        <simplebar class="customer-activity" id="myElement1">
          <div v-for="lead in previous_leads" :key="lead.l_id">
            <div class="list-intersets" v-if="lead.l_status == 6">
              <div class="list-cars">
                <a :href="'/lead-detail/' + lead.l_id">
                  <!--v-on:click="vehicleDetail(lead)-->
                  <img
                    v-if="lead.v_image"
                    class="img-fluid"
                    :src="lead.v_image"
                    alt=""
                  />
                  <img
                    v-if="!lead.v_image"
                    class="img-fluid"
                    src="../../assets/img/carplaceholder.png"
                    alt=""
                  />
                </a>
              </div>
              <div class="intersets-con">
                <h5 v-if="lead.l_status == 6">
                  Sold on {{ lead.l_created | formatDate("MMMM DD, YYYY") }}
                </h5>
                <h5 v-else-if="lead.l_status == 7">
                  Lost on {{ lead.l_created | formatDate("MMMM DD, YYYY") }}
                </h5>
                <h5 v-else>
                  {{ lead.l_created | formatDate("MMMM DD, YYYY") }}
                </h5>
                <h4>
                  <a style="color: black" :href="'/lead-detail/' + lead.l_id"
                    >{{ lead.v_year }} {{ lead.v_make }} {{ lead.v_model }}</a
                  >
                </h4>
                <p>Stock#:{{ lead.v_stock_no }}</p>
                <ul>
                  <li><a :href="'/lead-detail/' + lead.l_id"> Details</a></li>
                  <!-- <li><router-link :to="'/leads'">View leadsheet</router-link></li> -->
                </ul>
              </div>
            </div>
          </div>
        </simplebar>
      </div>
      <!-- Intersets-deal -->

      <!-- Communication Timeline -->
      <div class="Communication-timeline">
        <div class="deal-title">
          <h3>
            Communication Timeline
            <!-- <a class="viewall" href="#">View All</a> -->
          </h3>
        </div>
        <simplebar class="customer-activity" id="myElement3">
          <ul>
            <li
              v-for="com in communication"
              :key="com.al_id"
              :title="com.al_details"
            >
              <span> {{ com.al_created | formatDate("MMM DD, YYYY") }} </span>
              {{ com.al_details }}
            </li>
            <!-- <li><span> Mar 30, 2020 </span> Customer was Last contact on <a href="#">via facebook</a></li>
              <li><span> Mar 30, 2020 </span> Customer was Last contact on <a href="#">via facebook</a></li> -->
          </ul>
        </simplebar>
      </div>
      <!-- Communication Timeline -->
    </div>
    <!-- customerdetails-mid -->

    <!-- customerdetails-right -->
    <div class="customerdetails-right">
      <!-- appointments -->
      <div class="appointments">
        <div class="accordion" id="accordionExample3">
          <div class="card">
            <div class="card-header" id="headingOne3">
              <h2 class="mb-0">
                <button
                  class="btn btn-link"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseOne3"
                  aria-expanded="true"
                  aria-controls="collapseOne3"
                >
                  <div class="detais-title">
                    <h3>
                      <img
                        class="img-fluid arrow-down"
                        src="../../assets/img/arrow-down.svg"
                        alt=""
                      />Appointments
                    </h3>
                  </div>
                  <!-- <p>March 30, Tuesday <img class="img-fluid" src="../../assets/img/Vector (4).svg" alt=""></p> -->
                </button>
              </h2>
            </div>

            <div
              id="collapseOne3"
              class="collapse show"
              aria-labelledby="headingOne3"
              data-parent="#accordionExample3"
            >
              <div class="card-body">
                <simplebar class="active-meeting" id="myElement4">
                  <!-- active-meeting -->
                  <div
                    class="media"
                    v-for="apt in appointments"
                    :key="apt.ap_id"
                  >
                    <p class="mr-3">
                      {{ apt.ap_created | formatDate("HH:mm") }}
                    </p>
                    <!-- <div class="media-body" :class="apt.time_status == 'today' ? aptColors[0]: apt.time_status == 'past' ? aptColors[1]:aptColors[2]"> -->
                    <div
                      class="media-body"
                      :class="
                        apt.ap_status == 2
                          ? aptColors[1]
                          : apt.ap_status == 1
                          ? aptColors[2]
                          : apt.time_status == 'past'
                          ? aptColors[1]
                          : aptColors[0]
                      "
                    >
                      <h5 class="mt-0">
                        {{ apt.c_first_name }} {{ apt.c_last_name }}
                      </h5>
                      <p v-if="$storage.get('auth').user.user_type == 1">
                        Assigned to: {{ apt.name }}
                      </p>
                      <p>
                        {{
                          apt.ap_date_time | formatDate("MMMM DD, YYYY hh:mm A")
                        }}
                      </p>
                      <!-- <div class="appo-menu">
                          <ul>
                              <li><a data-toggle="tooltip" title="Confirmed" class="okay" :class="apt.ap_status == 1 ? 'okay-bg':''" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><g clip-path="url(#clip0)"><path d="M0.769569 4.64628C0.574307 4.45102 0.257724 4.45102 0.0624622 4.64628C-0.1328 4.84155 -0.1328 5.15813 0.0624622 5.35339L0.769569 4.64628ZM2.91602 7.49984L2.56246 7.85339C2.75772 8.04865 3.07431 8.04865 3.26957 7.85339L2.91602 7.49984ZM7.8529 3.27006C8.04816 3.0748 8.04816 2.75821 7.8529 2.56295C7.65764 2.36769 7.34106 2.36769 7.14579 2.56295L7.8529 3.27006ZM2.8529 4.64628C2.65764 4.45102 2.34106 4.45102 2.1458 4.64628C1.95053 4.84155 1.95053 5.15813 2.1458 5.35339L2.8529 4.64628ZM4.6458 7.14628C4.45053 7.34155 4.45053 7.65813 4.6458 7.85339C4.84106 8.04865 5.15764 8.04865 5.3529 7.85339L4.6458 7.14628ZM9.93623 3.27006C10.1315 3.0748 10.1315 2.75821 9.93623 2.56295C9.74097 2.36769 9.42439 2.36769 9.22913 2.56295L9.93623 3.27006ZM0.0624622 5.35339L2.56246 7.85339L3.26957 7.14628L0.769569 4.64628L0.0624622 5.35339ZM5.3529 7.85339L9.93623 3.27006L9.22913 2.56295L4.6458 7.14628L5.3529 7.85339ZM3.26957 7.85339L4.31124 6.81172L3.60413 6.10462L2.56246 7.14628L3.26957 7.85339ZM4.31124 6.81172L7.8529 3.27006L7.14579 2.56295L3.60413 6.10462L4.31124 6.81172ZM2.1458 5.35339L3.60413 6.81172L4.31124 6.10462L2.8529 4.64628L2.1458 5.35339Z" fill="#3151A1"/></g><defs><clipPath id="clip0"><rect width="10" height="10" fill="white"/></clipPath></defs></svg></a></li>
                              <li><a data-toggle="tooltip" title="Missed" :class="apt.ap_status == 2 ? 'notok-bg':''" class="notok" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none"><path d="M7.53033 1.53033C7.82322 1.23744 7.82322 0.762563 7.53033 0.46967C7.23744 0.176777 6.76256 0.176777 6.46967 0.46967L7.53033 1.53033ZM0.46967 6.46967C0.176777 6.76256 0.176777 7.23744 0.46967 7.53033C0.762563 7.82322 1.23744 7.82322 1.53033 7.53033L0.46967 6.46967ZM1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L1.53033 0.46967ZM6.46967 7.53033C6.76256 7.82322 7.23744 7.82322 7.53033 7.53033C7.82322 7.23744 7.82322 6.76256 7.53033 6.46967L6.46967 7.53033ZM6.46967 0.46967L0.46967 6.46967L1.53033 7.53033L7.53033 1.53033L6.46967 0.46967ZM0.46967 1.53033L6.46967 7.53033L7.53033 6.46967L1.53033 0.46967L0.46967 1.53033Z" fill="#3151A1"/></svg></a></li>
                          </ul>
                      </div> -->
                    </div>
                  </div>
                  <div class="media not-found" v-if="appointments.length == 0">
                    No Appointment Found.
                  </div>
                  <!-- active-meeting simplebar -->
                </simplebar>

                <!-- <simplebar class="active-meeting" id="myElement4">
                    <div class="media" v-for="apt in appointments" :key="apt.ap_id">
                        <p class="mr-3">{{apt.ap_created | formatDate('HH:mm')}}</p>
                        <div class="media-body" :class="apt.time_status == 'today' ? aptColors[0]: apt.time_status == 'past' ? aptColors[1]:aptColors[2]">
                            <h5 class="mt-0">{{apt.ap_description}}</h5>
                            <p v-if="$storage.get('auth').user.user_type == 1">Assigned to: {{apt.name}}</p>
                            <p>{{apt.ap_date_time | formatDate('MMMM DD, YYYY hh:mm A')}}</p>
                            <div class="appo-menu">
                                <ul>
                                    <li><a data-toggle="tooltip" title="Confirmed" class="okay" :class="apt.ap_status == 1 ? 'okay-bg':''" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><g clip-path="url(#clip0)"><path d="M0.769569 4.64628C0.574307 4.45102 0.257724 4.45102 0.0624622 4.64628C-0.1328 4.84155 -0.1328 5.15813 0.0624622 5.35339L0.769569 4.64628ZM2.91602 7.49984L2.56246 7.85339C2.75772 8.04865 3.07431 8.04865 3.26957 7.85339L2.91602 7.49984ZM7.8529 3.27006C8.04816 3.0748 8.04816 2.75821 7.8529 2.56295C7.65764 2.36769 7.34106 2.36769 7.14579 2.56295L7.8529 3.27006ZM2.8529 4.64628C2.65764 4.45102 2.34106 4.45102 2.1458 4.64628C1.95053 4.84155 1.95053 5.15813 2.1458 5.35339L2.8529 4.64628ZM4.6458 7.14628C4.45053 7.34155 4.45053 7.65813 4.6458 7.85339C4.84106 8.04865 5.15764 8.04865 5.3529 7.85339L4.6458 7.14628ZM9.93623 3.27006C10.1315 3.0748 10.1315 2.75821 9.93623 2.56295C9.74097 2.36769 9.42439 2.36769 9.22913 2.56295L9.93623 3.27006ZM0.0624622 5.35339L2.56246 7.85339L3.26957 7.14628L0.769569 4.64628L0.0624622 5.35339ZM5.3529 7.85339L9.93623 3.27006L9.22913 2.56295L4.6458 7.14628L5.3529 7.85339ZM3.26957 7.85339L4.31124 6.81172L3.60413 6.10462L2.56246 7.14628L3.26957 7.85339ZM4.31124 6.81172L7.8529 3.27006L7.14579 2.56295L3.60413 6.10462L4.31124 6.81172ZM2.1458 5.35339L3.60413 6.81172L4.31124 6.10462L2.8529 4.64628L2.1458 5.35339Z" fill="#3151A1"/></g><defs><clipPath id="clip0"><rect width="10" height="10" fill="white"/></clipPath></defs></svg></a></li>
                                    <li><a data-toggle="tooltip" title="Missed" :class="apt.ap_status == 2 ? 'notok-bg':''" class="notok" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none"><path d="M7.53033 1.53033C7.82322 1.23744 7.82322 0.762563 7.53033 0.46967C7.23744 0.176777 6.76256 0.176777 6.46967 0.46967L7.53033 1.53033ZM0.46967 6.46967C0.176777 6.76256 0.176777 7.23744 0.46967 7.53033C0.762563 7.82322 1.23744 7.82322 1.53033 7.53033L0.46967 6.46967ZM1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L1.53033 0.46967ZM6.46967 7.53033C6.76256 7.82322 7.23744 7.82322 7.53033 7.53033C7.82322 7.23744 7.82322 6.76256 7.53033 6.46967L6.46967 7.53033ZM6.46967 0.46967L0.46967 6.46967L1.53033 7.53033L7.53033 1.53033L6.46967 0.46967ZM0.46967 1.53033L6.46967 7.53033L7.53033 6.46967L1.53033 0.46967L0.46967 1.53033Z" fill="#3151A1"/></svg></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="media not-found" v-if="appointments.length == 0">No Appointmrnt Found.</div>
                </simplebar> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- appointments -->

      <!-- Tasks-area -->
      <div class="tasks-area">
        <div class="accordion" id="accordionExample4">
          <div class="card">
            <div class="card-header" id="headingOne4">
              <div class="mb-0">
                <button
                  class="btn btn-link"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseOne4"
                  aria-expanded="true"
                  aria-controls="collapseOne4"
                >
                  <div class="detais-title">
                    <h3>
                      <img
                        class="img-fluid arrow-down"
                        src="../../assets/img/arrow-down.svg"
                        alt=""
                      />
                      Tasks
                    </h3>
                  </div>
                </button>
              </div>
            </div>

            <div
              id="collapseOne4"
              class="collapse show"
              aria-labelledby="headingOne4"
              data-parent="#accordionExample4"
            >
              <div class="card-body">
                <div class="all-task simplebar" id="myElement3">
                  <simplebar class="active-meeting" id="myElement5">
                    <div class="media" v-for="task in tasks" :key="task.ta_id">
                      <!-- <div
                        class="media-body"
                        :class="
                          task.time_status == 'today'
                            ? aptColors[0]
                            : task.time_status == 'past'
                            ? aptColors[1]
                            : aptColors[2]
                        "
                      > -->
                      <div
                        class="media-body"
                        :class="
                          task.ta_status == 2
                            ? taskColors[1]
                            : task.ta_status == 0
                            ? taskColors[2]
                            : taskColors[0]
                        "
                      >
                        <h5 class="mt-0">{{ task.ta_name }}</h5>
                        <p v-if="$storage.get('auth').user.user_type == 1">
                          Assigned to: {{ task.name }}
                        </p>
                        <p>{{ task.ta_date | formatDate("DD MMM hh:mm A") }}</p>
                        <!-- <div class="appo-menu">
                                      <ul>
                                          <li><a data-toggle="tooltip" title="Confirmed" :id="'compl'+task.ta_id" class="okay" v-on:click.stop.prevent="updateTaskStatus(task.ta_id,1,task.time_status)" :class="task.ta_status == 1 ? 'okay-bg':''" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><g clip-path="url(#clip0)"><path d="M0.769569 4.64628C0.574307 4.45102 0.257724 4.45102 0.0624622 4.64628C-0.1328 4.84155 -0.1328 5.15813 0.0624622 5.35339L0.769569 4.64628ZM2.91602 7.49984L2.56246 7.85339C2.75772 8.04865 3.07431 8.04865 3.26957 7.85339L2.91602 7.49984ZM7.8529 3.27006C8.04816 3.0748 8.04816 2.75821 7.8529 2.56295C7.65764 2.36769 7.34106 2.36769 7.14579 2.56295L7.8529 3.27006ZM2.8529 4.64628C2.65764 4.45102 2.34106 4.45102 2.1458 4.64628C1.95053 4.84155 1.95053 5.15813 2.1458 5.35339L2.8529 4.64628ZM4.6458 7.14628C4.45053 7.34155 4.45053 7.65813 4.6458 7.85339C4.84106 8.04865 5.15764 8.04865 5.3529 7.85339L4.6458 7.14628ZM9.93623 3.27006C10.1315 3.0748 10.1315 2.75821 9.93623 2.56295C9.74097 2.36769 9.42439 2.36769 9.22913 2.56295L9.93623 3.27006ZM0.0624622 5.35339L2.56246 7.85339L3.26957 7.14628L0.769569 4.64628L0.0624622 5.35339ZM5.3529 7.85339L9.93623 3.27006L9.22913 2.56295L4.6458 7.14628L5.3529 7.85339ZM3.26957 7.85339L4.31124 6.81172L3.60413 6.10462L2.56246 7.14628L3.26957 7.85339ZM4.31124 6.81172L7.8529 3.27006L7.14579 2.56295L3.60413 6.10462L4.31124 6.81172ZM2.1458 5.35339L3.60413 6.81172L4.31124 6.10462L2.8529 4.64628L2.1458 5.35339Z" fill="#3151A1"/></g><defs><clipPath id="clip0"><rect width="10" height="10" fill="white"/></clipPath></defs></svg></a></li>
                                          <li><a data-toggle="tooltip" title="Reschedule" href="#" v-on:click.stop.prevent="reScheduleTask(task)" ><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><g clip-path="url(#clip0)"><path d="M3.41634 1.25C3.41634 0.973858 3.19248 0.75 2.91634 0.75C2.6402 0.75 2.41634 0.973858 2.41634 1.25H3.41634ZM7.58301 1.25C7.58301 0.973858 7.35915 0.75 7.08301 0.75C6.80687 0.75 6.58301 0.973858 6.58301 1.25H7.58301ZM1.66634 2.58333H2.91634V1.58333H1.66634V2.58333ZM2.91634 2.58333H7.08301V1.58333H2.91634V2.58333ZM7.08301 2.58333H8.33301V1.58333H7.08301V2.58333ZM8.33301 9.08333H1.66634V10.0833H8.33301V9.08333ZM1.33301 8.75V4.16667H0.333008V8.75H1.33301ZM1.33301 4.16667V2.91667H0.333008V4.16667H1.33301ZM8.66634 2.91667V4.16667H9.66634V2.91667H8.66634ZM8.66634 4.16667V8.75H9.66634V4.16667H8.66634ZM0.833008 4.66667H9.16634V3.66667H0.833008V4.66667ZM3.41634 2.08333V1.25H2.41634V2.08333H3.41634ZM7.58301 2.08333V1.25H6.58301V2.08333H7.58301ZM1.66634 9.08333C1.48225 9.08333 1.33301 8.93409 1.33301 8.75H0.333008C0.333008 9.48638 0.929962 10.0833 1.66634 10.0833V9.08333ZM8.33301 10.0833C9.06939 10.0833 9.66634 9.48638 9.66634 8.75H8.66634C8.66634 8.9341 8.5171 9.08333 8.33301 9.08333V10.0833ZM8.33301 2.58333C8.5171 2.58333 8.66634 2.73257 8.66634 2.91667H9.66634C9.66634 2.18029 9.06939 1.58333 8.33301 1.58333V2.58333ZM1.66634 1.58333C0.929962 1.58333 0.333008 2.18029 0.333008 2.91667H1.33301C1.33301 2.73257 1.48225 2.58333 1.66634 2.58333V1.58333Z" fill="#3151A1"/></g><defs><clipPath id="clip0"><rect width="10" height="10" fill="white"/></clipPath></defs></svg></a></li>
                                          <li><a data-toggle="tooltip" title="Missed" :id="'mis'+task.ta_id" v-on:click.stop.prevent="updateTaskStatus(task.ta_id,2,task.time_status)" :class="task.ta_status == 2 ? 'notok-bg':''" class="notok" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none"><path d="M7.53033 1.53033C7.82322 1.23744 7.82322 0.762563 7.53033 0.46967C7.23744 0.176777 6.76256 0.176777 6.46967 0.46967L7.53033 1.53033ZM0.46967 6.46967C0.176777 6.76256 0.176777 7.23744 0.46967 7.53033C0.762563 7.82322 1.23744 7.82322 1.53033 7.53033L0.46967 6.46967ZM1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L1.53033 0.46967ZM6.46967 7.53033C6.76256 7.82322 7.23744 7.82322 7.53033 7.53033C7.82322 7.23744 7.82322 6.76256 7.53033 6.46967L6.46967 7.53033ZM6.46967 0.46967L0.46967 6.46967L1.53033 7.53033L7.53033 1.53033L6.46967 0.46967ZM0.46967 1.53033L6.46967 7.53033L7.53033 6.46967L1.53033 0.46967L0.46967 1.53033Z" fill="#3151A1"/></svg></a></li>
                                          <li v-if="task.ta_status == '0'"><a data-toggle="tooltip" title="Delete" :id="'delete'+task.ts_id" v-on:click.stop.prevent="deleteTask(task.ta_id)" href="#"><img style="width: 11px;margin-top: 5px;" src="../../assets/img/Delete.svg" /></a></li>
                                      </ul>
                                  </div> -->
                      </div>
                    </div>
                    <div class="media not-found" v-if="tasks.length == 0">
                      No Task Found.
                    </div>
                  </simplebar>
                  <!-- <simplebar class="all-task">
                          <div class="media" v-for="task in tasks" :key="task.ta_id">
                              <p>
                              <label class="check2">
                                  <input type="checkbox">
                                  <span class="checkmarks"></span>
                              </label>
                              </p>
                              <div class="media-body">
                                  <h5 class="mt-0">{{task.ta_name}}</h5>
                                  <p>Scheduled for {{task.ta_date | formatDate('DD MMM')}} <a href="#" v-if="$storage.get('auth').user.user_type == 1">Assigned to {{task.name}}</a></p>

                                  <div class="appo-menu">
                                      <ul>
                                          <li><a data-toggle="tooltip" title="Confirmed" :id="'compl'+task.ta_id" class="okay" v-on:click.stop.prevent="updateTaskStatus(task.ta_id,1,task.time_status)" :class="task.ta_status == 1 ? 'okay-bg':''" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><g clip-path="url(#clip0)"><path d="M0.769569 4.64628C0.574307 4.45102 0.257724 4.45102 0.0624622 4.64628C-0.1328 4.84155 -0.1328 5.15813 0.0624622 5.35339L0.769569 4.64628ZM2.91602 7.49984L2.56246 7.85339C2.75772 8.04865 3.07431 8.04865 3.26957 7.85339L2.91602 7.49984ZM7.8529 3.27006C8.04816 3.0748 8.04816 2.75821 7.8529 2.56295C7.65764 2.36769 7.34106 2.36769 7.14579 2.56295L7.8529 3.27006ZM2.8529 4.64628C2.65764 4.45102 2.34106 4.45102 2.1458 4.64628C1.95053 4.84155 1.95053 5.15813 2.1458 5.35339L2.8529 4.64628ZM4.6458 7.14628C4.45053 7.34155 4.45053 7.65813 4.6458 7.85339C4.84106 8.04865 5.15764 8.04865 5.3529 7.85339L4.6458 7.14628ZM9.93623 3.27006C10.1315 3.0748 10.1315 2.75821 9.93623 2.56295C9.74097 2.36769 9.42439 2.36769 9.22913 2.56295L9.93623 3.27006ZM0.0624622 5.35339L2.56246 7.85339L3.26957 7.14628L0.769569 4.64628L0.0624622 5.35339ZM5.3529 7.85339L9.93623 3.27006L9.22913 2.56295L4.6458 7.14628L5.3529 7.85339ZM3.26957 7.85339L4.31124 6.81172L3.60413 6.10462L2.56246 7.14628L3.26957 7.85339ZM4.31124 6.81172L7.8529 3.27006L7.14579 2.56295L3.60413 6.10462L4.31124 6.81172ZM2.1458 5.35339L3.60413 6.81172L4.31124 6.10462L2.8529 4.64628L2.1458 5.35339Z" fill="#3151A1"/></g><defs><clipPath id="clip0"><rect width="10" height="10" fill="white"/></clipPath></defs></svg></a></li>
                                          <li><a data-toggle="tooltip" title="Missed" :id="'mis'+task.ta_id" v-on:click.stop.prevent="updateTaskStatus(task.ta_id,2,task.time_status)" :class="task.ta_status == 2 ? 'notok-bg':''" class="notok" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none"><path d="M7.53033 1.53033C7.82322 1.23744 7.82322 0.762563 7.53033 0.46967C7.23744 0.176777 6.76256 0.176777 6.46967 0.46967L7.53033 1.53033ZM0.46967 6.46967C0.176777 6.76256 0.176777 7.23744 0.46967 7.53033C0.762563 7.82322 1.23744 7.82322 1.53033 7.53033L0.46967 6.46967ZM1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L1.53033 0.46967ZM6.46967 7.53033C6.76256 7.82322 7.23744 7.82322 7.53033 7.53033C7.82322 7.23744 7.82322 6.76256 7.53033 6.46967L6.46967 7.53033ZM6.46967 0.46967L0.46967 6.46967L1.53033 7.53033L7.53033 1.53033L6.46967 0.46967ZM0.46967 1.53033L6.46967 7.53033L7.53033 6.46967L1.53033 0.46967L0.46967 1.53033Z" fill="#3151A1"/></svg></a></li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                          <div class="media not-found" v-if="tasks.length == 0">No Task Found.</div>
                      </simplebar> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Tasks-area -->
    </div>
    <!-- customerdetails-right -->

    <!-- car deatils Modal -->
    <div
      class="modal fade"
      id="vehicleDetailModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="vehicleDetailModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <a class="delas-colse close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"
              ><img
                class="img-fluid"
                src="../../assets/img/23. Closex.svg"
                alt=""
            /></span>
          </a>
          <div class="modal-body Intersets-modal">
            <div class="row">
              <div class="col-lg-7">
                <div class="exzoom" id="exzoom">
                  <div class="exzoom_img_box">
                    <ul class="exzoom_img_ul">
                      <li>
                        <img class="img-fluid" :src="v_detail.v_image" alt="" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-5">
                <div class="details-modal">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td>Stock#</td>
                        <td>{{ v_detail.v_stock_no }}</td>
                      </tr>
                      <tr>
                        <td>Mileage</td>
                        <td>{{ v_detail.v_mileage }}</td>
                      </tr>
                      <tr>
                        <td>Color</td>
                        <td>{{ v_detail.v_ext_color }}</td>
                      </tr>
                      <tr>
                        <td>Ext</td>
                        <td>{{ v_detail.v_ext_color }}</td>
                      </tr>
                      <tr>
                        <td>Interior</td>
                        <td>{{ v_detail.v_int_color }}</td>
                      </tr>
                      <tr>
                        <td>Engine</td>
                        <td>{{ v_detail.v_engine }}</td>
                      </tr>
                      <tr>
                        <td>Last Price</td>
                        <td>${{ v_detail.v_price }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-12">
                <div class="notes-con" v-if="v_detail.v_description">
                  <h3>Description</h3>
                  <p v-html="v_detail.v_description"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- car deatils Modal -->

    <div
      class="modal fade"
      id="editCustomerModal"
      role="dialog"
      aria-labelledby="editCustomerModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Edit Customer</h4>
            <a
              class="delas-colse close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>

          <form v-on:submit.prevent="editCustomer">
            <div class="modal-body">
              <div v-show="doCrop">
                <vue-cropper
                  :aspect-ratio="1 / 1"
                  :view-mode="1"
                  ref="cropper"
                  :src="cropImgUrl"
                  alt="Source Image"
                ></vue-cropper>
              </div>
              <div class="" v-show="!doCrop">
                <div class="user-img">
                  <div class="button-wrapper">
                    <span class="label">
                      <template v-if="cropImgUrl">
                        <img class="img-fluid" :src="cropImgUrl" alt="" />
                      </template>
                      <template v-else-if="customer_detail.c_img">
                        <img
                          style="width:100px:height:1000px"
                          class="img-fluid"
                          :src="customer_detail.c_img"
                          alt=""
                        />
                      </template>
                      <template v-else>
                        <img
                          v-if="!doCrop"
                          class="img-fluid"
                          :src="imgUrl"
                          alt=""
                        />
                      </template>
                    </span>
                    <input
                      type="file"
                      ref="file"
                      accept="image/*"
                      v-on:change="handleFileUpload"
                      class="upload upload-box"
                      placeholder="Upload File"
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <input
                      type="radio"
                      value="individual"
                      v-model="editCus.c_type"
                      name="utype"
                    />
                    Individual
                    <input
                      type="radio"
                      checked
                      value="company"
                      v-model="editCus.c_type"
                      name="utype"
                    />
                    Company
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label>First Name</label>
                    <input
                      v-model="editCus.c_first_name"
                      type="text"
                      class="form-control"
                      placeholder="Jeanne"
                    />
                    <div
                      class="validate-error-message"
                      v-if="$vd.editCus.c_first_name.$hasError === true"
                    >
                      {{ $vd.editCus.$errors.c_first_name[0] }}
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label>Last Name</label>
                    <input
                      v-model="editCus.c_last_name"
                      type="text"
                      class="form-control"
                      placeholder="Acevedo"
                    />
                    <div
                      class="validate-error-message"
                      v-if="$vd.editCus.c_last_name.$hasError === true"
                    >
                      {{ $vd.editCus.$errors.c_last_name[0] }}
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Email Address</label>
                  <input
                    v-model="editCus.c_email"
                    type="email"
                    class="form-control"
                    placeholder="example@gmail.com"
                  />
                </div>
                <div class="form-group">
                  <label>Company Name</label>
                  <input
                    v-model="editCus.c_company"
                    type="text"
                    class="form-control"
                    placeholder="Company Name"
                  />
                  <p style="color: red">{{ error }}</p>
                </div>
                <div class="form-group">
                  <label>Phone number</label>
                  <input
                    v-model="editCus.c_phone"
                    type="tel"
                    class="form-control"
                    placeholder=""
                  />
                  <!-- <vue-tel-input v-model="editCus.c_phone" v-bind="{mode:'international',dynamicPlaceholder:true,inputId:'edc_phone',enabledCountryCode:false,maxLen:15,validCharactersOnly:true,onlyCountries:['us'],defaultCountry:'us',placeholder:'Enter a phone number'}" ></vue-tel-input> -->
                  <div
                    class="validate-error-message"
                    v-if="$vd.editCus.c_phone.$hasError === true"
                  >
                    {{ $vd.editCus.$errors.c_phone[0] }}
                  </div>
                </div>
                <div class="form-group">
                  <label>Street</label>
                  <input
                    v-model="editCus.c_street"
                    type="text"
                    class="form-control"
                    placeholder="Street address"
                  />
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label>Zip/Postal Code</label>
                    <input
                      v-model="editCus.c_zip"
                      type="text"
                      class="form-control"
                      placeholder="Zip/Postal Code"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label>State/Province</label>
                    <input
                      v-model="editCus.c_state"
                      type="text"
                      class="form-control"
                      placeholder="State/Province"
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label>City:</label>
                    <input
                      v-model="editCus.c_city"
                      type="text"
                      class="form-control"
                      placeholder="Enter city"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label>Apt/Unit/Suite </label>
                    <input
                      v-model="editCus.c_apt"
                      type="text"
                      class="form-control"
                      placeholder="Apt/Unit/Suite"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label>Sex</label>
                  <select class="form-control" v-model="editCus.c_gender">
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                  <div
                    class="validate-error-message"
                    v-if="$vd.editCus.c_gender.$hasError === true"
                  >
                    {{ $vd.editCus.$errors.c_gender[0] }}
                  </div>
                </div>
                <div class="form-group">
                  <label for="inputAddress">Date of Birth</label>
                  <!-- <date-picker
                    :date="startTime"
                    :option="option"
                    :limit="limit"
                  ></date-picker> -->
                  <date-picker v-model="dob" :config="options"></date-picker>
                </div>
                <div class="form-group">
                  <label>Communication Preference</label>
                  <select
                    class="form-control"
                    v-model="editCus.c_communication_pref"
                  >
                    <option value="email">Email</option>
                    <option value="call">Call</option>
                    <option value="sms">SMS</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Vehicle Preference</label>
                  <select class="form-control" v-model="editCus.c_vehicle_pref">
                    <option value="new">New Vehicle</option>
                    <option value="used">Used</option>
                    <option value="leased">Leased</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Language Preference</label>
                  <select
                    class="form-control"
                    v-model="editCus.c_language_pref"
                  >
                    <option value="English">English</option>
                    <option value="French">French</option>
                    <option value="French">French</option>
                    <option value="Spanish">Spanish</option>
                    <option value="Chinese">Chinese</option>
                    <option value="Arabic">Arabic</option>
                    <option value="Farsi">Farsi</option>
                    <option value="Tagalog">Tagalog</option>
                    <option value="Vietnamese">Vietnamese</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Customer Rating</label>
                  <select
                    class="form-control"
                    v-model="editCus.c_customer_rating"
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Driving License</label>
                  <input
                    v-model="editCus.c_driving_licence"
                    type="text"
                    class="form-control"
                    placeholder="Enter driving license number"
                  />
                </div>
                <div class="form-group">
                  <label>SSN</label>
                  <input
                    v-model="editCus.c_ssn"
                    type="text"
                    class="form-control"
                    placeholder="Enter SSN"
                  />
                </div>
                <div class="form-group">
                  <div class="option-table setting-oppp">
                    <!-- check -->
                    <label class="containerw">
                      <input
                        type="checkbox"
                        @change="onChnagecontactHandeler($event)"
                        v-model="editCus.c_do_contract"
                      />
                      <span class="checkmark"></span>
                      Do not contact
                    </label>
                    <!-- check -->
                  </div>
                </div>
                <div class="form-group">
                  <label>Comment</label>
                  <input
                    v-model="editCus.c_comment"
                    type="text"
                    class="form-control"
                    placeholder="Enter comment"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button v-if="!doCrop" type="submit" class="rem-btn">
                <img
                  class="img-fluid"
                  src="../../assets/img/23. Close.svg"
                  alt=""
                />
                Update
              </button>
              <a
                href="#"
                v-if="doCrop"
                v-on:click="cancelCrop"
                data-dismiss="modal"
                class="add-btn"
                ><img
                  class="img-fluid"
                  src="../../assets/img/22. Delete.svg"
                  alt=""
                />
                Cancel</a
              >
              <a v-on:click="cropIt" class="rem-btn" v-if="doCrop">Crop</a>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- customer alredy exist modal-->
    <div
      class="modal fade"
      id="customeralredyexist"
      role="dialog"
      aria-labelledby="customeralredyexist"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <a
              class="delas-colse close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>

          <div class="modal-body">
            <p>Customer with same email or phone already exists</p>
          </div>
          <div class="modal-footer">
            <a @click="redirectToCustomer(editCus.c_id)" class="rem-btn">
              View Customer Detail
            </a>

            <button @click="merge(editCus.c_id)" class="rem-btn">Merge</button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="mergeCustomer"
      role="dialog"
      aria-labelledby="customeralredyexist"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <a
              class="delas-colse close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <form @submit.prevent="mergeCustomer">
            <div class="modal-body">
              <div class="perfor-search">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <autocomplete
                      ref="autocomplete"
                      placeholder="Search Customer"
                      v-model="search"
                      :source="
                        base_url +
                        'get/customers' +
                        '/' +
                        this.$route.params.id +
                        '/'
                      "
                      results-property="data"
                      :results-display="formattedAutocompleteDisplay"
                      :request-headers="autocompleteAuthHeaders"
                      @selected="selectCustomer"
                    >
                    </autocomplete>
                    <!-- <input type="search" class="form-control" id="exampleInputEmail1" placeholder="Seach by agent or team " /><img class="img-fluid per-sericon" src="../../assets/img/search.svg" alt="" /> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="rem-btn">Merge</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- permission modal-->
    <div
      class="modal fade"
      id="permission"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel"></h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <div class="modal-body">
            <p style="color: black; padding: 30px">{{ permission_error }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
import $ from "jquery";
import VueDaval from "vue-daval";
import moment from "moment";
// import DateRangePicker from "vue2-daterange-picker";
//  import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
//  import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import VueCropper from "vue-cropperjs";
import Autocomplete from "vuejs-auto-complete";
import Swal from "sweetalert2";
// import myDatepicker from "vue-datepicker";
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";

export default {
  name: "Detail",
  components: {
    simplebar,
    VueCropper,
    Autocomplete,
    datePicker,
  },
  mixins: [VueDaval],
  vdRules: {
    editCus: {
      c_first_name: { required: true },
      c_last_name: { required: true },
      c_phone: { required: true },
      c_gender: { required: true },
    },
  },
  created() {
    this.getCustomerDetail();
  },
  computed: {
    autocompleteAuthHeaders() {
      return { Authorization: "Bearer " + this.$storage.get("auth").token };
    },
  },
  data() {
    return {
      dob: "",
      options: {
        format: "MM/DD/YYYY",
        useCurrent: false,
      },
      error: "",
      startTime: {
        time: "",
      },
      endtime: {
        time: "",
      },
      option: {
        type: "day",
        week: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
        month: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        format: "MM-DD-YYYY",
        placeholder: "",
        inputStyle: {
          display: "block",
          width: "100%",
          padding: "0.375rem 0.75rem",
          "font-size": "14px",
          "font-weight": "400",
          "line-height": "1.5",
          color: "#343945",
          "background-color": "#fff",
          "background-clip": "padding-box",
          border: "1px solid #d5d8df",
          "border-radius": "0.25rem",
          transition:
            "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
        },
        color: {
          header: "#ccc",
          headerText: "#f00",
        },
        buttons: {
          ok: "Ok",
          cancel: "Cancel",
        },
        overlayOpacity: 0.5, // 0.5 as default
        dismissible: true, // as true as default
      },
      base_url: axios.defaults.baseURL,
      search: "",
      mergeid: "",
      match_cus_id: "",
      imgUrl: require("../../assets/img/profile.svg"),
      cropImgUrl: null,
      doCrop: false,
      editCus: {},
      customer_detail: {},
      active_leads: [],
      appointments: [],
      communication: [],
      previous_leads: [],
      lost_leads: [],
      ufDate: { startDate: "", endDate: "" },
      tasks: [],
      v_detail: {},
      permission_error: "",
      aptColors: ["green-meeting", "red-meeting", "blue-meeting"],
      taskColors: ["green-meeting", "blue-meeting", "red-meeting"],
    };
  },
  methods: {
    onChnagecontactHandeler(event) {
      if (event.target.checked) {
        this.editCus.c_do_contract = true;
      } else {
        this.editCus.c_do_contract = false;
      }
    },
    redirectToCustomer(id) {
      $("#customeralredyexist").modal("hide");
      setTimeout(() => {
        this.$router.push({ name: "customerDetail", params: { id: id } });
      }, 400);
    },
    deleteCustomer() {
      Swal.fire({
        title: "Are you sure you want to delete?",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#FF0000",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios
            .get("cus/delete/" + this.$route.params.id, {
              headers: {
                Authorization: "Bearer " + this.$storage.get("auth").token,
              },
            })
            .then((res) => {
              const data = res.data;
              console.log(data);
              if (data.status) {
                window.location.href = "/leads";
              }
              if (data.status === "permission") {
                this.permission_error = data.message;
                $("#permission").modal("show");
              }
              if (data.status == false) {
                Swal.fire(data.message, "", "success");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    },
    mergeCustomer() {
      Swal.fire({
        title: "Are you sure you want to merge?",
        showCancelButton: true,
        confirmButtonText: "Merge",
        denyButtonText: `Don't Merge`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios
            .post(
              "mergeCustomerData",
              { id: this.$route.params.id, mergeid: this.mergeid },
              {
                headers: {
                  Authorization: "Bearer " + this.$storage.get("auth").token,
                },
              }
            )
            .then((res) => {
              console.log(res.data);
              if (res.data.status) {
                $("#mergeCustomer").modal("hide");
                $(".autocomplete__inputs input").val("");
              }
              if (res.data.status == "permission") {
                this.permission_error = res.data.message;
                $("#permission").modal("show");
              }
              if (res.data == false) {
                alert(res.data);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    },
    mergeCustomerModal() {
      $("#mergeCustomer").modal("show");
    },
    formattedAutocompleteDisplay(result) {
      return result.text;
    },
    selectCustomer(result) {
      console.log(result.selectedObject);
      this.mergeid = result.selectedObject.id;
    },
    addleadsheet() {
      var data = {
        cus_fname: this.editCus.c_first_name,
        cus_lname: this.editCus.c_last_name,
        email: this.editCus.c_email,
        phone: this.editCus.c_phone,
        img: this.editCus.c_img,
      };
      this.$root.$emit("addleadsheet", data);
    },
    merge(id) {
      axios
        .get("merge/" + id + "/" + this.match_cus_id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          const data = res.data;
          if (data.status) {
            window.location.href = "/customer/detail/" + data.id;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleFileUpload(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.cropImgUrl = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
      this.doCrop = true;

      //this.imgUrl = URL.createObjectURL(this.$refs.file.files[0]);
    },
    cropIt() {
      this.cropImgUrl = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.editCus.c_img = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.doCrop = false;
    },
    cancelCrop() {
      this.doCrop = false;
    },
    editCustomer() {
      if (this.editCus.c_type == "company") {
        if (this.editCus.c_company == null || this.editCus.c_company == "") {
          this.error = "Company field is required";
          return false;
        }
      }
      let startDate = this.dob;
      this.editCus.c_dob = moment(startDate).format("YYYY-MM-DD");
      //console.log(this.editCus)
      axios
        .post("update_customer", this.editCus, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.success) {
            this.$root.$emit("action", "new_activity");
            this.error = "";
            this.customer_detail = this.editCus;
            this.startTime = {
              time: this.customer_detail.c_dob,
            };
            $("#editCustomerModal").modal("hide");
          } else if (res.data.status == false) {
            $("#customeralredyexist").modal("show");
            $("#editCustomerModal").modal("hide");
            this.match_cus_id = res.data.id;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCustomerDetail() {
      axios
        .get("customer/detail/" + this.$route.params.id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            const data = res.data;
            this.editCus = data.success.customer_detail;
            this.editCus.C_dob = moment(
              data.success.customer_detail.c_dob
            ).format("MM/DD/YYYY");

            this.startTime = {
              time: this.editCus.c_dob,
            };

            this.customer_detail = data.success.customer_detail;
            console.log(res.data.success);
            this.active_leads = data.success.active_leads;
            this.appointments = data.success.appointments;
            this.communication = data.success.communication;
            this.previous_leads = data.success.previous_leads;
            this.lost_leads = data.success.lost_leads;
            this.tasks = data.success.tasks;
            console.log(data.success);
          }
          if (res.data.success == false) {
            this.$router.push("/leads");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    vehicleDetail(v) {
      console.log(v);
      this.v_detail = v;
      $("#vehicleDetailModal").modal("show");
    },
  },
};
</script>
<style lang="scss" scoped>
.lisense {
  width: 200px;
  height: 200px;
  margin-top: 30px;
}
.cov-vue-date {
  width: 100% !important;
}
</style>
